.logo {
  height: 75px;
  float: left;
  display: flex;
  align-items: center;
  padding: 0 25px;
  cursor: pointer;
}

.logo img {
  width: 60px;
}

.brand {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 10px;
}

.brand span:nth-child(1) {
  font-weight: bold;
  color: #3ca1ad;
  font-size: 20px;
}

.brand span:nth-child(2) {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 4px;
  color: rgb(230, 83, 69);
}

nav {
  position: fixed;
  width: 100%;
  z-index: 99;
  height: 80px;
}
nav.filled {
  background: rgba(6, 30, 41);
}

nav.scrolled {
  background: rgba(6, 30, 41, 0.9);
}
nav ul {
  float: right;
  list-style: none;
  padding: 25px;
  margin: 0;
  font-size: 18px;
}
nav li {
  float: left;
}
nav a {
  color: #fff;
  text-decoration: none;
  margin: 5px 0 0 35px;
  display: block;
}
nav a:hover {
  color: #ededed;
}
