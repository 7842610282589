.wrapper {
  z-index: 98;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /*background: linear-gradient(-135deg, #c850c0, #4158d0);*/
  /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
  /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%);*/
  /* clip-path: circle(25px at calc(0% + 45px) 45px); */
  background: rgb(6, 30, 41);
  clip-path: circle(0px at calc(100% - 45px) 45px);
  transition: all 0.3s ease-in-out;
}
#active:checked ~ .wrapper {
  clip-path: circle(75%);
}
.menu-btn {
  position: absolute;
  z-index: 99;
  right: 20px;
  /* left: 20px; */
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  /*background: linear-gradient(-135deg, #c850c0, #4158d0);*/
  /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
  /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%); */
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease-in-out;
}
#active:checked ~ .menu-btn {
  color: #fff;
}
#active:checked ~ .menu-btn i:before {
  content: "\f00d";
}
.wrapper ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}
.wrapper ul li {
  margin: 15px 0;
  width: 100%;
}
.wrapper ul li a {
  color: none;
  text-decoration: none;
  font-size: 26px;
  font-weight: 500;
  padding: 5px 30px;
  color: #fff;
  border-radius: 50px;
  background: #000;
  position: relative;
  line-height: 50px;
  transition: all 0.3s ease;
  margin: 0;
}
.wrapper ul li a:after {
  position: absolute;
  content: "";
  background: rgb(230, 83, 69);
  /* background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0); */
  /*background: linear-gradient(375deg, #1cc7d0, #2ede98);*/
  width: 104%;
  height: 110%;
  left: -2%;
  top: -5%; /* if the font is 'Oswald'*/
  border-radius: 50px;
  transform: scaleY(0);
  z-index: -1;
  /* animation: rotate 1.5s linear infinite; */
  transition: transform 0.3s ease;
}
.wrapper ul li a:hover:after {
  transform: scaleY(1);
}
.wrapper ul li a:hover {
  color: #fff;
}
input[type="checkbox"] {
  display: none;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  width: 100%;
  color: #202020;
}
.content .title {
  font-size: 40px;
  font-weight: 700;
}
.content p {
  font-size: 35px;
  font-weight: 600;
}

@keyframes rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.navlist-icon {
  display: none;
}

/* breaking points */

@media screen and (max-width: 769px) {
  .navlist-menu {
    display: none;
  }
  .navlist-icon {
    display: block;
  }
}

@media screen and (max-width: 426px) {
  .wrapper ul li a {
    font-size: 20px;
  }
}

@media screen and (max-width: 321px) {
  .wrapper ul li a {
    font-size: 16px;
  }
}
