@font-face {
  font-family: "Mabry Pro";
  src: url("../../assets/fonts/MabryPro/Mabry-Pro.woff") format("woff"),
    url("../../assets/fonts/MabryPro/Mabry-Pro.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../../assets/fonts/MabryPro/Mabry-Pro-Light.woff") format("woff"),
    url("../../assets/fonts/MabryPro/Mabry-Pro-Light.woff") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../../assets/fonts/MabryPro/Mabry-Pro-Medium.woff") format("woff"),
    url("../../assets/fonts/MabryPro/Mabry-Pro-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../../assets/fonts/MabryPro/Mabry-Pro-Bold.woff") format("woff"),
    url("../../assets/fonts/MabryPro/Mabry-Pro-Bold.woff2") format("woff2");
  font-weight: 700;
}

body {
  font-family: "Mabry Pro" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(20, 20, 22);
  color: rgb(255, 255, 255);
  margin: 0px;
}

/* Sidebar */
.floating-menu {
  font-weight: 100;
  background: #fff;
  width: 200px;
  height: 100%;
  /* padding-left: 50px; */
  position: fixed;
  z-index: 100;
  /* -webkit-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.2); */
  right: -200px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  color: #222;

  top: 0;
}

.floating-menu:hover,
.floating-menu:focus {
  transform: translate3d(-200px, 0, 0);
  animation-timing-function: 1s ease-in;
}

.floating-menu .floating-title {
  top: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: rotate(270deg);
  left: -115px;
  font-weight: 800;
  font-size: 15px;

  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.floating-menu .floating-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: 100;
  padding-left: 15px;
}

.floating-menu .floating-nav li {
  padding-bottom: 30px;
  list-style-type: none;
}

.floating-menu .floating-nav li a {
  display: block;
  /* text-decoration: none; */
  color: inherit;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-size: 18px;
}

.floating-menu .floating-nav li a:hover {
  color: #aaa;
}