.main {
  padding: 160px 80px 80px 80px;
  background-color: #fff;
  height: calc(100vh - 220px);

  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.screen {
  position: relative;
  background: #3e3e3e;
  border-radius: 15px;
}

.screen:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #061e29;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
  width: 100%;
}

.screen-body-item {
  flex: 1;
  padding: 50px 100px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #061e29;
  font-size: 32px;
  font-weight: bolder;
}

.screen-body-item.left {
  background: url(../../assets/images/login.png) no-repeat center center;
  background-size: contain;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;
  display: flex;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: center;
  padding-top: 15px;
}

.app-form-control {
  width: 100%;
  padding: 14px 10px;
  background: none;
  border: 1px solid #666;
  border-radius: 8px;
  color: #333;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;
}

.app-form-control.danger {
  border-color: #e74c3c;
}

.app-form-control.success {
  border-color: #2ecc71;
}

.app-form-control::placeholder {
  color: #666;
}

.app-form-control:focus {
  border-color: #ddd;
}

.app-form-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #3ca1ad;
  background-color: #3ca1ad;
  width: 100px;
  height: 48px;
}

.app-form-button:hover {
  border: 1px solid #e65345;
  background-color: #e65345;
}

/* captcha */
.modern-react-captcha {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.modern-react-captcha__captchaText {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modern-react-captcha__reloadBtn {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  padding: 10px;
  border: 1px solid #e65345;
  background-color: #e65345;
}

.modern-react-captcha__reloadBtn > img {
  filter: invert(1);
  -webkit-filter: invert(1);
}

.modern-react-captcha__reloadBtn:hover {
  border: 1px solid #3ca1ad;
  background-color: #3ca1ad;
}

.modern-react-captcha__inputField {
  padding: 14px 10px;
  color: #333;
  font-size: 16px;
  transition: border-color 0.2s;
  outline: none;
  flex: 3;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #666;
}

.modern-react-captcha__inputField.danger {
  border-color: #e74c3c;
}

.modern-react-captcha__inputField.success {
  border-color: #2ecc71;
}

.modern-react-captcha__inputField:focus {
  border-color: #ddd;
}

.modern-react-captcha__inputField.danger:focus {
  border-color: #c0392b;
}

.modern-react-captcha__inputField.success:focus {
  border-color: #27ae60;
}

@media screen and (max-width: 769px) {
  .screen-body-item {
    padding: 10px;
  }
  .screen-body-item.left {
    background-size: contain;
    background-position-y: top;
  }
}

@media screen and (max-width: 554px) {
  .main {
    padding: 80px;
  }
  .screen-body-item.left {
    display: none;
  }
  .screen-body {
    align-items: center;
  }
}

@media screen and (max-width: 426px) {
  .main {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 376px) {
  .main {
    padding: 80px 30px;
  }
}
@media screen and (max-width: 320px) {
  .main {
    padding: 80px 20px;
  }
}
